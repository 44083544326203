import React from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import Typography from '../Typography';
import styles from './styles.module.sass';

export default ({ 
  children,
  weight = 'light',
  variant = 'h5',
  align = 'center',
  color = 'light',
  containerClass = '',
  className = ''
}) => (
  <Grid item md={12} className={clsx(
      styles.container,
      containerClass
    )}>
    <Typography variant={variant}
      color={color} align={align}
      weight={weight}
      className={className}>
      {children}
    </Typography>
  </Grid>
);