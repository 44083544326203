import React from 'react';
import Content from '../views/Fleet/Main';

export default ({location}) => (
  <Content location={location} seo={{
    title: "Fleet - fleet management solution for companies",
    lang: 'en',
    description: "Fleet management complete solution designed to manage vehicle-related tasks within your fleet and ensure safety for every driver.",
    meta: [{
      name: 'keywords',
      content: 'Fleet management solution'
    }]
  }}/>
);