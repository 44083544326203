import React from 'react';
import Typography from '../../../../../components/Typography';
import styles from './styles.module.sass';

export default ({
  value,
  text,
  placeholder = '',
  setFormData,
}) => (
  <div className={styles.container}>
    <Typography variant='h5'
      align='left'
      weight='light'
      className={styles.textContainer}>
      {text}
    </Typography>
    <input type='number' className={styles.input}
      defaultValue={value}
      onChange={e => setFormData(Math.floor(e.target.value))}
      placeholder={placeholder}/>
  </div>
)