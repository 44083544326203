import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Grid } from '@material-ui/core';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../../locale';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`query {
      image: imageSharp (fluid: {
        originalName: { eq: "Fleet-Main-Advantages.jpg" }
      }) {
        fluid(maxWidth: 1257, maxHeight: 612, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }`}
    render={({ image }) => (
      <Grid container justifyContent='center' alignContent='center' alignItems='flex-end'
        className={styles.container}>
        <Grid item container xs={12} sm={12} md={12} lg={12} className={styles.imageContainer}
          alignContent='center'
          alignItems='flex-end'>
          <Grid item xs={12} xl={12}>
            <Img imgStyle={{ objectFit: 'contain' }}
              objectFit='contain'
              className={styles.image}
              fluid={image.fluid}
              alt={i18n('Fleet management for any fleet size and vehicle')}/>
          </Grid>
        </Grid>
      </Grid>
    )
  }/>
);